<template>
  <b-card header="DIstances" header-bg-variant="primary" header-text-variant="white">
    <!-- toggle button -->
    <div class="d-flex my-1">
      <span :class="isCities ? 'text-muted' : 'text-primary'" @click="isCities = false">Districts</span>
      <b-form-checkbox v-model="isCities" name="check-button" switch class="mx-1" />
      <span :class="!isCities ? 'text-muted' : 'text-primary'" @click="isCities = false">Cities </span>
    </div>
    <!-- table -->
    <b-table
      sticky-header
      :fields="computedFields"
      :items="computedProductsList"
      bordered
      hover
      responsive
      class="shadow-sm rounded products-table"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      small
    >
    </b-table>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BTable, BCard, BFormCheckbox } from "bootstrap-vue";

export default {
  name: "Distances",
  components: {
    BTable,
    BCard,
    BFormCheckbox,
  },
  data() {
    return {
      isCities: true,
      cityFields: [
        {
          key: "from",
          label: "From",
          formatter: (value, key, item) => `${item.from.cityNameGeo} / ${item.from.cityNameEng}`,
        },
        {
          key: "to",
          label: "To",
          formatter: (value, key, item) => `${item.to.cityNameGeo} / ${item.to.cityNameEng}`,
        },
        { key: "distanceInMeters", label: "Distance", formatter: (value) => this.formatDistance(value) },
      ],
      districtFields: [
        {
          key: "from",
          label: "From",
          formatter: (value, key, item) => `${item.from.districtNameGeo} / ${item.from.districtNameEng}`,
        },
        {
          key: "to",
          label: "To",
          formatter: (value, key, item) => `${item.to.districtNameGeo} / ${item.to.districtNameEng}`,
        },
        { key: "distanceInMeters", label: "Distance", formatter: (value) => this.formatDistance(value) },
      ],
    };
  },
  computed: {
    ...mapState("parametersModule", ["districtDistances", "cityDistances"]),
    computedProductsList() {
      if (this.isCities) return this.cityDistances;
      return this.districtDistances;
    },
    computedFields() {
      if (this.isCities) return this.cityFields;
      return this.districtFields;
    },
  },
  created() {
    this.getDistances();
  },
  methods: {
    ...mapActions("parametersModule", ["getCityDistances", "getDistrictDistances"]),
    async getDistances() {
      try {
        await this.getCityDistances();
        await this.getDistrictDistances();
      } catch (error) {
        console.log(error);
      }
    },
    formatDistance(distance) {
      if (distance >= 1000) {
        return `${Math.round(distance / 1000.0)} km`;
      }
      if (distance >= 100) {
        return `${Math.round(distance)} m`;
      }
      return `${distance.toFixed(1)} m`;
    },
  },
};
</script>

<style></style>
